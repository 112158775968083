/* eslint-disable @next/next/no-img-element */

import Checklist from "./Checklist";

const ChecklistItem = ({ children }: any) => (
  <div className="text-zinc-500 space-y-2 mt-4">{children}</div>
);

const StepCard = ({
  step,
  title,
  subtitle,
  description,
  checklistItems,
  imageSrc,
  isRightAligned = false,
}: any) => (
  <div
    className={`grid items-center grid-cols-1 gap-4 mt-6 list-none lg:grid-cols-2 lg:gap-14 ${
      isRightAligned ? "lg:grid-flow-col-dense" : ""
    }`}
  >
    <div>
      <span className="text-xs font-bold tracking-wide text-gray-500 uppercase">
        Step {step}
      </span>
      <p className="mt-8 text-[2rem] font-semibold tracking-tight text-gray-900">
        {title}
        <span className="lg:block lg:text-gray-600">{subtitle}</span>
      </p>
      <p className="mt-4 text-base text-gray-500 max-w-xl">{description}</p>
      {checklistItems && (
        <ChecklistItem>
          {checklistItems.map((item: any, index: any) => (
            <Checklist key={index}>
              <div key={index}>{item}</div>
            </Checklist>
          ))}
        </ChecklistItem>
      )}
    </div>
    <div
      className={`p-2 border bg-gray-50 rounded-3xl ${
        isRightAligned ? "lg:order-first" : ""
      }`}
    >
      <div className="h-full overflow-hidden bg-white border shadow-lg rounded-3xl">
        <img
          alt={`${title} thumbnail`}
          className="relative w-full rounded-2xl drop-shadow-2xl"
          src={imageSrc}
        />
      </div>
    </div>
  </div>
);

export default StepCard;
