const FeatureItem = ({
  title,
  description,
  icon,
}: {
  title: any;
  description: any;
  icon: any;
}) => (
  <div className="space-y-2">
    <div className="flex items-center justify-start gap-2">
      {icon}
      <p className="text-lg font-medium leading-6 text-black">{title}</p>
    </div>
    <div className="text-base text-gray-500">{description}</div>
  </div>
);

export default FeatureItem;
