import React from "react";
import Checklist from "./Checklist";

const FeatureItem = ({ children }: any) => (
  <li className="flex items-start gap-3">
    <Checklist>
      <p>{children}</p>
    </Checklist>
  </li>
);

const PricingCard = ({
  title,
  price,
  description,
  features,
  buttonText,
  backgroundClass,
  textColorClass,
  buttonTextClass,
  onClick,
}: any) => (
  <div
    className={`flex flex-col justify-between p-8 rounded-3xl h-max ${backgroundClass}`}
  >
    <div className="flex flex-col">
      <h3 id="tier-standard" className={`mt-4 font-medium ${textColorClass}`}>
        {title}
      </h3>
      <div className={`order-first text-2xl font-medium`}>
        {price} <span>/month</span>
      </div>
      <p className={`mt-2 text-xs italic ${textColorClass}`}>{description}</p>
      <ul
        role="list"
        className={`flex flex-col gap-2 mt-12 text-sm ${textColorClass}`}
      >
        {features.map((feature: any, index: any) => (
          <FeatureItem key={index}>{feature}</FeatureItem>
        ))}
      </ul>
    </div>
    <div className="mt-8">
      <button
        onClick={onClick}
        className={`flex items-center justify-center w-full h-10 px-4 py-2 text-sm font-semibold transition-all rounded-lg ${buttonTextClass}`}
        aria-describedby="tier-standard"
      >
        {buttonText}
      </button>
    </div>
  </div>
);

export default PricingCard;
