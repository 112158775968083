/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useState } from 'react'
import { HeroSection } from './HeroSection'
import { Statistic } from './Statistic'
import { classNames } from '@lib/classNames'
import StepCard from './StepCard'
import PricingCard from './PricingCard'
import { GitHubIcon, SlackIcon, TwitterIcon } from './icons/socials'
import SocialCard from './SocialCard'
import { Button } from './ui/Button'
import Collapsible from './Collapsible'
import Image from 'next/image'
import { SVGLineGlowAnimateContainer } from './LineGlow'
import FeatureItem from './FeatureItem'
import {
  ChartBarIcon,
  ShieldCheckIcon,
  CursorClickIcon,
  LightningBoltIcon,
} from '@heroicons/react/solid'
import MarqueeAnimation from './MarqueeAnimation'
import { useRouter } from 'next/router'
import { isWindowAvailable } from '@utils/is-window-available'
import { isDevEnv } from '@utils/is-dev-env'

export const LandingPage: React.FC = () => {
  const [isClient, setIsClient] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return (
    <div className={classNames('bg-gray-100 font-inter font-heading')}>
      <HeroSection />
      <div className="relative z-10">
        <div className="px-5 overflow-auto shadow-sm bg-gradient-to-b from-white to-gray-50">
          {/* <div className="grid grid-cols-2 border-l border-r border-dashed max-w-7xl sm:mx-auto sm:grid-cols-3 not-sm-grid-border sm:divide-x sm:divide-dashed">
            <Statistic statistic="10x" description="More Secure" />
            <Statistic statistic="5%" description="Deeper Analytics" />
            <Statistic statistic="2x" description="More Integrations" />
            <div className="flex items-center justify-center text-gray-500 sm:hidden">
              <span className="mr-3 text-2xl">🦄</span> And much more!
            </div>
          </div> */}
          <MarqueeAnimation />
        </div>
        <main>
          <div className="px-5 border-t border-dashed">
            <div className="mx-auto border-l border-r border-dashed max-w-7xl">
              <section className="w-full">
                <div className="w-full px-8 py-10 pb-16 mx-auto space-y-24 border-dashed max-w-7xl">
                  <StepCard
                    step="1"
                    title="Create your first form"
                    description="Just sign up and start creating forms with our minimal editor. No credit cards required."
                    checklistItems={[
                      'Simple form in seconds',
                      'No coding required',
                    ]}
                    imageSrc="/images/steps/step-1.png"
                  />
                  <StepCard
                    step="2"
                    title="Integrate other tools"
                    description="Connect all the apps you already love to use with our built-in integrations."
                    checklistItems={[
                      'Get notified via Slack',
                      'Analyze responses in Google Sheets',
                    ]}
                    imageSrc="/images/steps/step-2.png"
                    isRightAligned
                  />
                  <StepCard
                    step="3"
                    title="Share everywhere"
                    description="A HeroForm is shareable everywhere on the web. Want to integrate into your webapp? Take advantage of the Webhooks!"
                    checklistItems={[
                      'Embed on Website',
                      'Extend with Webhooks',
                    ]}
                    imageSrc="/images/steps/step-3.png"
                  />
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>

      <div className="flex flex-col items-center justify-center w-full px-8 py-10 mx-auto border-t border-l border-r border-dashed max-w-7xl">
        <section className="w-full">
          <div className="grid items-center justify-center w-full grid-cols-1 gap-0 text-left lg:gap-14 lg:grid-cols-3">
            <div className="relative items-center w-full gap-12 m-auto lg:inline-flex md:order-first">
              <div className="p-2 border bg-gray-50 rounded-3xl relative z-[88] w-full">
                <div className="flex w-full p-10 bg-white border shadow-lg md:p-8 rounded-3xl lg:space-y-6 lg:flex-col">
                  <FeatureItem
                    title="Go Above and Beyond"
                    description="With our increasing integrations you can extend HeroForm to every use-case."
                    icon={
                      <LightningBoltIcon className="text-black" width={20} />
                    }
                  />
                  <FeatureItem
                    title="We care about security"
                    description="We follow all the security best practices, so our forms are always secure."
                    icon={<ShieldCheckIcon className="text-black" width={20} />}
                  />
                </div>{' '}
              </div>
            </div>

            <div className="flex items-center justify-center order-first w-full gap-10 lg:gap-0">
              <div className="rotate-0 lg:rotate-90 opacity-40">
                <SVGLineGlowAnimateContainer />
              </div>
              <div className="relative">
                <Image
                  src={require('../../public/images/new-logo.png')}
                  className="flex justify-center items-center w-full max-w-[120px] mx-auto relative z-[999]"
                  alt="logo"
                />

                <div
                  style={{
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                  className="absolute mx-auto -top-2"
                >
                  <span className="flex h-[120px] scale-75 w-[120px] justify-center items-center mx-auto">
                    <span className="absolute inline-flex w-full h-full duration-100 bg-gray-200 opacity-75 animate-ping animation-delay-100 rounded-3xl"></span>
                    <span className="animate-ping !duration-400 animation-delay-300 absolute inline-flex h-full w-full rounded-3xl bg-gray-300 opacity-75"></span>
                    <span className="animate-ping !duration-700 animation-delay-500 absolute inline-flex h-full w-full rounded-3xl bg-gray-200 opacity-75"></span>
                  </span>
                </div>
              </div>
              <div className="rotate-0 lg:rotate-90 opacity-40">
                <SVGLineGlowAnimateContainer />
              </div>
            </div>

            <div className="relative items-center w-full gap-12 m-auto lg:inline-flex md:order-first">
              <div className="p-2 border bg-gray-50 rounded-3xl relative z-[88] w-full">
                <div className="flex w-full p-10 bg-white border shadow-lg md:p-8 rounded-3xl lg:space-y-6 lg:flex-col">
                  <FeatureItem
                    title="Deeper analytics"
                    description="Connect your forms to Google Sheets for easy analysis of responses."
                    icon={<ChartBarIcon className="text-black" width={20} />}
                  />
                  <FeatureItem
                    title="Use Cases"
                    description="Suitable for product-market fit surveys, customer research & much more."
                    icon={<CursorClickIcon className="text-black" width={20} />}
                  />
                </div>{' '}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="flex flex-col items-center justify-center w-full px-8 py-10 mx-auto border-t border-l border-r border-dashed max-w-7xl">
        <div className="h-full px-8 pb-12 mx-auto md:px-12 lg:px-32 max-w-7xl">
          <div className="mx-auto max-w-7xl px-4l lg:text-center sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto lg:max-w-none">
              <div>
                <p className="text-[2rem] font-semibold tracking-tighter text-gray-900 text-center">
                  Start for free and
                  <span className="text-gray-500 md:block">
                    upgrade to unlock more features
                  </span>
                </p>
              </div>
              <ul className="flex flex-col items-start justify-center gap-1 mt-8 text-base font-normal tracking-wide text-center list-none text-zinc-500 lg:flex-row lg:gap-4">
                <li className="w-full min-w-max">
                  <em>Full refund policy</em>
                </li>
                <li className="hidden lg:block">·</li>
                <li className="w-full min-w-max">
                  <em>Used by hundreds of other users</em>
                </li>
                <li className="hidden lg:block">·</li>
                <li className="w-full min-w-max">
                  <em>Dedicated and fast support</em>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="grid items-center w-full max-w-2xl grid-cols-1 gap-6 mx-auto sm:gap-4 lg:max-w-none lg:grid-cols-3">
          <PricingCard
            title="Free Forever"
            price="$0"
            description="For personal/non-commercial projects."
            features={[
              'Max 1,000 Responses',
              'Basic Question Types',
              'Basic Integrations',
              'Starter Analytics',
              'Basic Notifications',
            ]}
            buttonText="Create Free Form"
            onClick={() => {
              router.push('/continue')
            }}
            backgroundClass="bg-white text-zinc-800"
            textColorClass="text-zinc-500"
            buttonTextClass="bg-zinc-50 text-zinc-600 hover:text-zinc-500 hover:bg-white border"
          />
          <PricingCard
            title="Pro Subscription"
            price="$9.99"
            description="For powerful forms with heavy demand."
            features={[
              'All Question Types',
              'In-Depth Analytics',
              'All Integrations',
              'Priority Support',
              'Theme Customization',
              'Intro/Outro Customization',
              'Developer API & Webhooks',
              'All Features Unlocked',
            ]}
            buttonText="Start Free Trial"
            backgroundClass="bg-gradient-to-t from-zinc-800 via-zinc-700 to-zinc-500 rounded-3xl"
            textColorClass="text-white"
            buttonTextClass="text-sm flex font-semibold items-center justify-center px-4 py-2 rounded-lg transition-all h-10 text-white bg-gradient-to-b from-white/[.105] hover:to-white/[.25] ring-1 ring-inset ring-white/20 to-white/[.15] w-full"
            onClick={() => {
              if (isWindowAvailable()) {
                // @ts-ignore
                window?.Paddle?.Checkout?.open?.({
                  product: isDevEnv() ? 42157 : 806411,
                })
              }
            }}
          />
          <PricingCard
            title="Custom"
            price="_"
            description="For teams with more security and performance needs."
            features={[
              'Dedicated 24/7 Service',
              'Self Hosting License',
              'On Demand Integrations',
              'On Demand Themes',
            ]}
            buttonText="Contact Us"
            backgroundClass="bg-white text-zinc-800"
            textColorClass="text-zinc-500"
            buttonTextClass="bg-zinc-50 text-zinc-600 hover:text-zinc-500 hover:bg-white border"
          />
        </div>
      </div>

      <section>
        <div className="w-full px-8 py-10 mx-auto space-y-12 border-t border-l border-r border-dashed max-w-7xl">
          <div>
            <p className="text-[2rem] font-semibold tracking-tighter text-gray-900 text-center">
              Community
              <span className="text-gray-500 md:block">
                Help Shape the Product
              </span>
            </p>
          </div>
          <ul
            role="list"
            className="grid max-w-2xl grid-cols-1 gap-6 mx-auto sm:gap-4 lg:max-w-none lg:grid-cols-3"
          >
            <SocialCard
              text="If you want to stay connected with us and learn about the latest developments in the project, make sure to follow us on Twitter."
              author="Stay up to date"
              title="Follow @heroform_io"
              icon={<TwitterIcon width={50} height={50} />}
              link={'https://twitter.com/heroform_io'}
            />
            <SocialCard
              text="Join our Slack community and help us to build the best open source Form infrastructure for the web."
              author="Join the discussion"
              title="Join @HeroForm"
              icon={<SlackIcon width={50} height={50} />}
              link={
                'https://heroform.slack.com/join/shared_invite/zt-1in0oj3br-fH4Nk87hLISIY3Oejhc9aw#/shared-invite/email'
              }
            />
            <SocialCard
              text="Found a bug? Have a feature request? Or are you a developer looking to contribute directly to the project? Find us on Github!"
              author="Collaborate with us"
              title="Star @heroform_io"
              icon={<GitHubIcon width={50} height={50} />}
              link={'https://github.com/nfuad/HeroForm'}
            />
          </ul>
        </div>
      </section>

      <section>
        <div className="flex flex-col items-center justify-center w-full gap-10 px-8 py-10 mx-auto border-t border-l border-r border-dashed max-w-7xl">
          <div>
            <p className="text-[2rem] font-semibold tracking-tighter text-gray-900 text-center">
              FAQ
              <span className="text-gray-500 md:block">You Ask, We Answer</span>
            </p>
          </div>

          <div className="grid w-full grid-cols-2 gap-3">
            <Collapsible header="Why use HeroForm instead of Typeform?">
              No hate on Typeform - they’ve popularized the multi-page form
              model for the whole web, which definitely improved the
              form-filling UX for everyone. But over time, they stopped
              innovating. Their forms are now bloated and loads super slow,
              leading to bad UX and lower response rates. Not to mention their
              super-pricey model and lack of a basic free tier for those who
              can’t afford to pay. Enter HeroForm. We have a clear product
              vision: allow folks to build multi-page forms for free and collect
              responses wherever they want. We’re a bunch of indie-hackers who
              just love building useful products. That’s why we have optimized
              our forms so they have a great UX, loads ultra-fast (near 100
              lighthouse score), plus what you get for free in HeroForm costs
              you a whooping 100$ in Typeform - while the features & experience
              are much superior in HeroForm. And when you do pay, it’s less than
              a cup of coffee a month and we use the money to maintain server
              costs and further the open-source project ^^
            </Collapsible>
            <Collapsible header="Why not use other form alternatives?">
              We’ve been where you are - trying to find a good solution for
              collecting responses from people, with good UX, and doesn’t break
              the bank. Unfortunately, we couldn’t find a holistic solution that
              works for everyone, but also offers a very generous free tier. So
              we built it. And you can use it for free.
            </Collapsible>
            <Collapsible header="Can I use this under my own domain?">
              Of course, you can! We offer you 2 ways for this: 1. The Embed:
              You can get our embed code from the settings and put it in your
              own web app. You can also set a custom redirect URL in the form
              settings so whenever a user submits the form, they get redirected
              to whatever page you want to redirect to. Tip from us: if you make
              the embed take up the full width and height of your page, you can
              make it seem like the HeroForm form is just as similar to the
              other pages on your page. 2. Adding a domain in HeroForm: You can
              add your own domain (or subdomain) to HeroForm in the settings -
              and then you will have to make the required changes in your
              domain’s DNS settings. However, this feature is under development
              and might take some time to be ready. Feel free to send us an
              email so we can prioritize this up top! ^^
            </Collapsible>
            <Collapsible header="Can I self-host this?">
              Of course, you can! The whole codebase is fully available on
              GitHub in a public repo so you can self-host on your own infra
              anytime you want. Having said that, we don’t recommend this -
              because when you do self-host, you’ll have to maintain the servers
              all by yourself and when we do push new changes every other day,
              you’ll have to manually get them in your infra as well. Also, by
              not self-hosting, you contribute to our open-source efforts and
              also improve the overall experience of all the other users!
            </Collapsible>
            <Collapsible header="Is there an API that I can use?">
              Oh for sure! You can go into the form settings to add your own
              webhook URL. So anytime a form is submitted, we send all the data
              over to the webhook. Also, if you’re using HeroForm in your own
              domain by embedding it, you can add your own query parameters and
              we’ll pass them over when we send the data to the webhook URL. Why
              would you ever want that you ask? Well, imagine a scenario where
              you’re using a HeroForm form in your app, would you not want to
              identify which user submitted the form? You can just pass in a
              user ID as a query parameter and we’ll send it over to the webhook
              URL on each submission so you’ll be able to identify the users. In
              other words, this allows you to never have to build a form
              yourself even for the web apps. How cheeky is that? ^^
            </Collapsible>
            <Collapsible header="Is there any hidden pricing?">
              There are none! We don’t give you any surprise bills, really. We
              just love building useful products and we are not greedy for
              money. That’s why our free tier has been intentionally made quite
              generous so everyone can use this regardless of their financial
              ability ^^ and when you do upgrade, it’s a flat fee per month that
              never breaks the bank.
            </Collapsible>
          </div>
        </div>
      </section>

      <section>
        <div className="w-full px-8 py-10 mx-auto space-y-12 border-t border-l border-r border-dashed max-w-7xl">
          <div className="relative -mb-10 overflow-hidden">
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
              <img
                src="/images/dashboard.png"
                alt="App screenshot"
                className="mb-[-15%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                width={1200}
              />
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-100 pt-[7%]" />
              </div>
            </div>
          </div>
          <div className="p-2 border bg-gray-50 rounded-3xl relative z-[88]">
            <div className="p-10 text-center bg-white border shadow-lg md:p-20 rounded-3xl">
              <p className="text-4xl font-semibold tracking-tighter text-black">
                Just Start for Free
              </p>
              <p className="mt-4 text-base text-gray-500">
                Create a form for free with HeroForm today.
                <span className="md:block">
                  Doesn't hurt to give it a try...
                </span>
              </p>
              <Button
                onClick={() => {
                  router.push('/continue')
                }}
                size="sm"
                className="inline-flex items-center justify-center w-full h-12 gap-3 px-5 py-3 mt-5 text-xs font-semibold text-white transition-all border hover:border-gray-200 md:w-auto rounded-xl focus:outline-none"
              >
                Get Started - It's Free
              </Button>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="w-full px-8 py-10 mx-auto space-y-12 border-t border-l border-r border-dashed max-w-7xl">
          <div className="pt-12 border-gray-300 xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="text-black">
              <div className="inline-flex items-center gap-3">
                <p className="text-2xl font-bold uppercase">HeroForm</p>
              </div>
              <p className="mt-2 text-sm text-gray-500 lg:w-4/5">
                Build blazing fast, interactive forms for research, feedback,
                and more.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-8 mt-12 lg:grid-cols-3 lg:mt-0 xl:col-span-2">
              <div></div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-black font-display">Legal</h3>
                <ul role="list" className="mt-4 space-y-2">
                  <li>
                    <a
                      href="https://heroform.io/terms"
                      className="text-sm text-gray-500 hover:text-black"
                    >
                      Terms
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://heroform.io/privacy"
                      className="text-sm text-gray-500 hover:text-black"
                    >
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-black font-display">Socials</h3>
                <ul role="list" className="mt-4 space-y-2">
                  <li>
                    <a
                      href="https://twitter.com/heroform_io"
                      className="text-sm text-gray-500 hover:text-black"
                    >
                      @heroform_io
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/nfuad/HeroForm"
                      className="text-sm text-gray-500 hover:text-black"
                    >
                      nfuad/heroform
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-12 md:flex-row md:items-center md:justify-between">
            <p className="text-left">
              <span className="mx-auto mt-2 text-sm text-gray-500 lg:mx-0">
                © 2023. All rights Reserved. Please don't copy us.
              </span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
