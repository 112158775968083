import { classNames } from '@lib/classNames'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

export interface Props {
  open?: boolean
  header: string | React.ReactNode
  children: any
}

const Icon = () => {
  return (
    // <div className="w-[50px] h-[50px]">
    //   <div className="rounded-[14px] flex flex-row justify-center items-center h-full">
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke-width="1.5"
    //       stroke="currentColor"
    //       className="w-6 h-6"
    //     >
    //       <path
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         d="M12 4.5v15m7.5-7.5h-15"
    //       />
    //     </svg>
    //   </div>
    // </div>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  )
}

const Collapsible: React.FC<Props> = ({ open, children = '', header = '' }) => {
  const [isOpen, setIsOpen] = useState(open)
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0)
  const ref = useRef<HTMLDivElement>(null)
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height)
    })
    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, isOpen])

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height)
    else setHeight(0)
  }, [isOpen])

  return (
    <>
      <div
        style={{
          boxShadow: isOpen ? '0px 0px 100px -20px rgba(0, 0, 0, 0.1)' : '',
          // @ts-ignore
          backgroundImage: !isOpen && 'none',
        }}
        className="rounded-[24px] bg-gradient-to-r p-[2px] transition-all from-gray-100 to-gray-100 h-max"
      >
        <div className="w-full max-w-7xl rounded-[22px] bg-white [border:1px_solid_#e4e4e4] flex flex-col justify-between transition-all">
          <div
            className="w-full cursor-pointer rounded-xl"
            onClick={handleFilterOpening}
          >
            <div className={`flex justify-between w-full py-[23px] px-[32px]`}>
              <div
                className={classNames(
                  isOpen ? 'text-lg font-semibold' : '',
                  'flex items-center transition-all justify-center font-medium text-start text-black',
                )}
              >
                {header}
              </div>
              <i
                className={`flex justify-center items-center transition-all ${
                  isOpen ? 'rotate-180' : ''
                }`}
              >
                <Icon />
              </i>
            </div>
          </div>
          <div
            className="overflow-hidden duration-200 ease-in-out transition-height"
            style={{ height }}
          >
            <div ref={ref}>
              <div className={`p-[23px_32px] pt-0 text-black/60`}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Collapsible
