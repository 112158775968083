import { motion, useScroll, useTransform } from 'framer-motion'
import Image from 'next/image'
import React, { useEffect } from 'react'

import dashboard from '../../public/images/dashboard.png'
import { Navbar } from './Navbar'
import { Waitlist } from './Waitlist'
import { ListCheck } from './ListCheck'

import Link from 'next/link'
import { useScreen } from '@lib/useScreen'
import { Button } from './ui/Button'
import { useRouter } from 'next/router'

interface HeroSectionProps {}

export const HeroSection: React.FC<HeroSectionProps> = ({}) => {
  const { isSmallerThanTablet } = useScreen()
  const { scrollY } = useScroll()
  const rotateX = useTransform(scrollY, [0, 500], [15, 0])
  const scale = useTransform(scrollY, [0, 500], [1, 1.11])

  const headerContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const headerRow = {
    hidden: { opacity: 0, y: -12 },
    show: { opacity: 1, y: 0 },
  }

  const router = useRouter()

  useEffect(() => {
    const iframe = document.getElementById('myIframe')
    // @ts-ignore
    iframe.contentWindow.postMessage({ theme: 'light' }, '*')
  }, [])

  return (
    <header className="relative bg-gray-50">
      {/* <motion.div
        transition={{ duration: 0.1 }}
        variants={headerRow}
        initial="hidden"
        animate="show"
      >
        <Navbar />
      </motion.div> */}

      <div className="absolute inset-0 z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]">
        <div className="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-white opacity-20 blur-[100px]"></div>
      </div>

      <section className="!z-[999] relative">
        <div className="px-8 py-24 mx-auto md:px-12 lg:px-32 max-w-7xl">
          <div className="text-center">
            <div className="relative">
              <Image
                src={require('../../public/images/new-logo.png')}
                className="flex justify-center items-center w-full max-w-[120px] mx-auto relative z-[999]"
                alt="logo"
              />

              <div
                style={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
                className="absolute mx-auto -top-2"
              >
                <span className="flex h-[120px] scale-75 w-[120px] justify-center items-center mx-auto">
                  <span className="absolute inline-flex w-full h-full duration-100 bg-gray-200 opacity-75 animate-ping animation-delay-100 rounded-3xl"></span>
                  <span className="animate-ping !duration-400 animation-delay-300 absolute inline-flex h-full w-full rounded-3xl bg-gray-200 opacity-75"></span>
                  <span className="animate-ping !duration-700 animation-delay-500 absolute inline-flex h-full w-full rounded-3xl bg-gray-200 opacity-75"></span>
                </span>
              </div>
            </div>

            <p className="mt-8 text-4xl font-semibold tracking-tighter text-gray-900 lg:text-5xl !leading-[3.5rem]">
              The Open Source Form
              <span className="text-gray-600 md:block">
                Service For The Web
              </span>
            </p>
            <p className="mt-4 text-base text-gray-500">
              The simplest way to create stunning, blazing fast forms in
              seconds, for free.
              <span className="md:block">
                Store responses wherever you need. Time to{' '}
                <b>create forms like a Hero</b>.
              </span>
            </p>

            <div className="flex items-center justify-center w-full mt-8 gap-x-2">
              <Button
                onClick={() => {
                  router.push('/continue')
                }}
                size="sm"
                className="inline-flex items-center justify-center w-full h-12 gap-3 px-5 py-3 text-xs font-semibold text-white transition-all border hover:border-gray-200 md:w-auto rounded-xl focus:outline-none"
              >
                Get Started - It's Free
              </Button>
              <Button
                onClick={() => {
                  router.push('/continue')
                }}
                variant="ghost"
                size="sm"
                className="border hover:border-gray-300 transition-all inline-flex items-center justify-center w-full h-12 gap-3 px-5 py-3 md:w-auto rounded-xl bg-gray-300/10 hover:bg-gray-300/20 focus:outline-none text-xs text-gray-600 font-semibold !bg-white hover:!bg-gray-50"
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <motion.header
        variants={headerContainer}
        initial="hidden"
        animate="show"
        transition={{ delay: 0.1 }}
        className="relative z-10 flex flex-col items-center max-w-3xl px-5 pt-8 pb-24 mx-auto md:pt-16 md:pb-32"
      >
        <motion.h1
          variants={headerRow}
          className="font-semibold font-display text-3xl sm:text-4xl md:text-5xl xl:text-[3.5rem] text-center md:!leading-tight"
        >
          <span className="tracking-tight text-gray-500">
            Earn from publishing
          </span>{" "}
          <br />
          Reward your top readers
        </motion.h1>
        <motion.p
          variants={headerRow}
          className="text-gray-500 text-center mt-4 md:mt-6 !leading-loose text-sm sm:text-base lg:text-[1.1rem] max-w-lg sm:max-w-xl lg:max-w-2xl"
        >
          <strong className="tracking-tight text-gray-600">
            Quit Medium & Substack
          </strong>{" "}
          and publish on Presage. Brainstorm, draft, and revise without
          distractions. Reward your readers for referring your articles.
        </motion.p>
        <motion.div className="flex justify-center w-full" variants={headerRow}>
          <Waitlist />
        </motion.div>
        <motion.ul
          variants={headerRow}
          className="flex items-center justify-between w-full max-w-sm mt-6 sm:w-auto sm:max-w-none sm:space-x-10"
        >
          <li className="flex items-center flex-shrink-0 space-x-2">
            <ListCheck />
            <span className="text-sm font-semibold text-gray-600">
              {isSmallerThanTablet ? "Free Plan" : "Generous Free Plan"}
            </span>
          </li>
          <li className="flex items-center flex-shrink-0 space-x-2">
            <ListCheck />
            <span className="text-sm font-semibold text-gray-600">
              {isSmallerThanTablet ? "Referrals" : "Grow with Referrals"}
            </span>
          </li>
          <li className="flex items-center flex-shrink-0 space-x-2">
            <ListCheck />
            <span className="text-sm font-semibold text-gray-600">
              Open Source
            </span>
          </li>
        </motion.ul>
      </motion.header> */}

      <div className="relative z-10 justify-end hidden w-full max-w-6xl mx-auto md:flex md:w-5/6 lg:w-3/4 pb-28">
        <Image
          src={require('../../public/images/left-plane.png')}
          alt="Screenshot of dashboard"
          quality={100}
          placeholder="blur"
          priority
          width={258}
          height={567}
          className="w-[258px] absolute -top-[340px] left-20 -z-[50] opacity-50"
        />
        <Image
          src={require('../../public/images/right-plane.png')}
          alt="Screenshot of dashboard"
          quality={100}
          placeholder="blur"
          priority
          width={272}
          height={556}
          className="w-[272px] absolute -top-[200px] right-0 -z-[50] opacity-50"
        />

        <motion.div
          style={{ rotateX, scale, transformPerspective: 1500 }}
          className="p-2 border border-gray-100 rounded-3xl w-full bg-white shadow-2xl shadow-gray-200 z-[99]"
        >
          <iframe
            id="myIframe"
            className="h-[700px] transition-all w-full rounded-3xl"
            src="https://heroform.io/26ZKMi89"
            title="Interactive demo"
          />
          {/* <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 w-full mx-auto bottom-0 bg-gradient-to-t from-gray-50 pt-[20%]" />
          </div> */}
        </motion.div>
      </div>
      <div className="absolute bottom-0 z-0 hidden w-full md:block h-96 bg-gradient-to-b from-zinc-50 to-zinc-200" />
    </header>
  )
}
