import Marquee from "react-fast-marquee";

function MarqueeAnimation() {
  return (
    <div className="w-full py-7 mx-auto max-w-7xl">
      <div className="relative z-10 flex flex-col gap-3 py-5 mx-auto text-zinc-700 border border-zinc-200 rounded-3xl grayscale bg-white">
        <Marquee>
          <ul className="flex justify-between gap-5 space-x-3 uppercase list-disc">
            <li>User feedback</li>
            <li>Job applications</li>
            <li>Customer research</li>
            <li>Viral loops</li>
            <li>Onboarding surveys</li>
            <li>Early access pages</li>
            <li>Referrals</li>
          </ul>
        </Marquee>
        <div className="border-b-2 border-zinc-200 border-dashed" />
        <Marquee direction="right">
          <ul className="flex gap-5 space-x-3 uppercase list-disc">
            <li>User feedback</li>
            <li>Onboarding surveys</li>
            <li>Customer research</li>
            <li>Job applications</li>
            <li>Viral loops</li>
            <li>Referrals</li>
            <li>Early access pages</li>
          </ul>
        </Marquee>
        {/* <div className="absolute z-50 -left-1 top-0 w-[80px] h-full bg-gradient-to-r from-black" />
            <div className="absolute z-50 -right-1 top-0 w-[80px] h-full bg-gradient-to-l from-black" /> */}
      </div>
    </div>
  );
}

export default MarqueeAnimation;
