export const TwitterIcon = ({
  noContainer = false,
  width = 30,
  height = 30,
}) => {
  if (noContainer) {
    return (
      <div className="flex items-center justify-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.6504 21.5333C42.6456 21.9795 41.5662 22.2798 40.4333 22.4153C41.5903 21.7223 42.4776 20.625 42.8955 19.3167C41.814 19.9583 40.6149 20.4245 39.3381 20.6765C38.3165 19.5876 36.8612 18.9072 35.2505 18.9072C32.1572 18.9072 29.6498 21.4157 29.6498 24.5079C29.6498 24.9468 29.7002 25.3752 29.7947 25.7836C25.1401 25.5505 21.0136 23.3203 18.2501 19.931C17.7692 20.7584 17.493 21.7202 17.493 22.7481C17.493 24.6906 18.481 26.4052 19.9836 27.409C19.0659 27.3796 18.2018 27.1276 17.4468 26.7087C17.4468 26.7328 17.4468 26.7549 17.4468 26.779C17.4468 29.4933 19.3767 31.757 21.9397 32.2705C21.4704 32.3986 20.9748 32.4668 20.4634 32.4668C20.1033 32.4668 19.7515 32.4311 19.4103 32.3671C20.1232 34.592 22.1917 36.2122 24.6424 36.2573C22.7262 37.7599 20.3112 38.6555 17.6862 38.6555C17.2347 38.6555 16.7885 38.6293 16.3496 38.5768C18.8286 40.1654 21.7717 41.0925 24.9354 41.0925C35.2379 41.0925 40.8701 32.5582 40.8701 25.1568C40.8701 24.9142 40.8648 24.6727 40.8543 24.4323C41.9494 23.6416 42.8997 22.6557 43.6504 21.5333Z"
            fill="#2AA2ED"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="w-[50px] h-[50px] overflow-hidden rounded-[8.05px] bg-[aliceblue] border-[0.57px] border-[#f0efff] flex justify-center items-center shadow">
      <div className="flex items-center justify-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.6504 21.5333C42.6456 21.9795 41.5662 22.2798 40.4333 22.4153C41.5903 21.7223 42.4776 20.625 42.8955 19.3167C41.814 19.9583 40.6149 20.4245 39.3381 20.6765C38.3165 19.5876 36.8612 18.9072 35.2505 18.9072C32.1572 18.9072 29.6498 21.4157 29.6498 24.5079C29.6498 24.9468 29.7002 25.3752 29.7947 25.7836C25.1401 25.5505 21.0136 23.3203 18.2501 19.931C17.7692 20.7584 17.493 21.7202 17.493 22.7481C17.493 24.6906 18.481 26.4052 19.9836 27.409C19.0659 27.3796 18.2018 27.1276 17.4468 26.7087C17.4468 26.7328 17.4468 26.7549 17.4468 26.779C17.4468 29.4933 19.3767 31.757 21.9397 32.2705C21.4704 32.3986 20.9748 32.4668 20.4634 32.4668C20.1033 32.4668 19.7515 32.4311 19.4103 32.3671C20.1232 34.592 22.1917 36.2122 24.6424 36.2573C22.7262 37.7599 20.3112 38.6555 17.6862 38.6555C17.2347 38.6555 16.7885 38.6293 16.3496 38.5768C18.8286 40.1654 21.7717 41.0925 24.9354 41.0925C35.2379 41.0925 40.8701 32.5582 40.8701 25.1568C40.8701 24.9142 40.8648 24.6727 40.8543 24.4323C41.9494 23.6416 42.8997 22.6557 43.6504 21.5333Z"
            fill="#2AA2ED"
          />
        </svg>
      </div>
    </div>
  );
};

export const GitHubIcon = ({
  noContainer = false,
  width = 30,
  height = 30,
}) => {
  if (noContainer) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1193_30277)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30 16C22.265 16 16 22.265 16 30C16 36.195 20.0075 41.4275 25.5725 43.2825C26.2725 43.405 26.535 42.985 26.535 42.6175C26.535 42.285 26.5175 41.1825 26.5175 40.01C23 40.6575 22.09 39.1525 21.81 38.365C21.6525 37.9625 20.97 36.72 20.375 36.3875C19.885 36.125 19.185 35.4775 20.3575 35.46C21.46 35.4425 22.2475 36.475 22.51 36.895C23.77 39.0125 25.7825 38.4175 26.5875 38.05C26.71 37.14 27.0775 36.5275 27.48 36.1775C24.365 35.8275 21.11 34.62 21.11 29.265C21.11 27.7425 21.6525 26.4825 22.545 25.5025C22.405 25.1525 21.915 23.7175 22.685 21.7925C22.685 21.7925 23.8575 21.425 26.535 23.2275C27.655 22.9125 28.845 22.755 30.035 22.755C31.225 22.755 32.415 22.9125 33.535 23.2275C36.2125 21.4075 37.385 21.7925 37.385 21.7925C38.155 23.7175 37.665 25.1525 37.525 25.5025C38.4175 26.4825 38.96 27.725 38.96 29.265C38.96 34.6375 35.6875 35.8275 32.5725 36.1775C33.08 36.615 33.5175 37.455 33.5175 38.7675C33.5175 40.64 33.5 42.145 33.5 42.6175C33.5 42.985 33.7625 43.4225 34.4625 43.2825C37.2417 42.3442 39.6567 40.558 41.3676 38.1753C43.0785 35.7926 43.9992 32.9333 44 30C44 22.265 37.735 16 30 16Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1193_30277">
            <rect
              width="28"
              height="28"
              fill="white"
              transform="translate(16 16)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <div className="w-[50px] h-[50px] overflow-hidden rounded-[8.05px] bg-gray-100 border-[0.57px] border-[#f0efff] flex justify-center items-center shadow">
      <div className="flex items-center justify-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1193_30277)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30 16C22.265 16 16 22.265 16 30C16 36.195 20.0075 41.4275 25.5725 43.2825C26.2725 43.405 26.535 42.985 26.535 42.6175C26.535 42.285 26.5175 41.1825 26.5175 40.01C23 40.6575 22.09 39.1525 21.81 38.365C21.6525 37.9625 20.97 36.72 20.375 36.3875C19.885 36.125 19.185 35.4775 20.3575 35.46C21.46 35.4425 22.2475 36.475 22.51 36.895C23.77 39.0125 25.7825 38.4175 26.5875 38.05C26.71 37.14 27.0775 36.5275 27.48 36.1775C24.365 35.8275 21.11 34.62 21.11 29.265C21.11 27.7425 21.6525 26.4825 22.545 25.5025C22.405 25.1525 21.915 23.7175 22.685 21.7925C22.685 21.7925 23.8575 21.425 26.535 23.2275C27.655 22.9125 28.845 22.755 30.035 22.755C31.225 22.755 32.415 22.9125 33.535 23.2275C36.2125 21.4075 37.385 21.7925 37.385 21.7925C38.155 23.7175 37.665 25.1525 37.525 25.5025C38.4175 26.4825 38.96 27.725 38.96 29.265C38.96 34.6375 35.6875 35.8275 32.5725 36.1775C33.08 36.615 33.5175 37.455 33.5175 38.7675C33.5175 40.64 33.5 42.145 33.5 42.6175C33.5 42.985 33.7625 43.4225 34.4625 43.2825C37.2417 42.3442 39.6567 40.558 41.3676 38.1753C43.0785 35.7926 43.9992 32.9333 44 30C44 22.265 37.735 16 30 16Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1193_30277">
              <rect
                width="28"
                height="28"
                fill="white"
                transform="translate(16 16)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export const SlackIcon = ({ noContainer = false, width = 30, height = 30 }) => {
  if (noContainer) {
    return (
      <div className="flex items-center justify-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36.8202 18.8717C36.8202 17.2858 35.5344 16 33.9485 16C32.3626 16 31.0768 17.2858 31.0768 18.8717C31.0768 19.772 31.0768 25.8651 31.0768 26.769C31.0768 28.3549 32.3626 29.6407 33.9485 29.6407C35.5344 29.6407 36.8202 28.3549 36.8202 26.769C36.8202 25.8651 36.8202 19.772 36.8202 18.8717Z"
            fill="#33D375"
          />
          <path
            d="M44 26.7682C44 28.3541 42.7142 29.6399 41.1283 29.6399C40.2704 29.6399 38.2566 29.6399 38.2566 29.6399C38.2566 29.6399 38.2566 27.4962 38.2566 26.7682C38.2566 25.1823 39.5424 23.8965 41.1283 23.8965C42.7142 23.8965 44 25.1823 44 26.7682Z"
            fill="#33D375"
          />
          <path
            d="M18.8717 23.1797C17.2858 23.1797 16 24.4655 16 26.0514C16 27.6373 17.2858 28.9231 18.8717 28.9231C19.772 28.9231 25.8651 28.9231 26.769 28.9231C28.3549 28.9231 29.6407 27.6373 29.6407 26.0514C29.6407 24.4655 28.3549 23.1797 26.769 23.1797C25.8651 23.1797 19.772 23.1797 18.8717 23.1797Z"
            fill="#40C4FF"
          />
          <path
            d="M26.7708 16C28.3567 16 29.6425 17.2858 29.6425 18.8717C29.6425 19.7297 29.6425 21.7435 29.6425 21.7435C29.6425 21.7435 27.4987 21.7435 26.7708 21.7435C25.1848 21.7435 23.899 20.4576 23.899 18.8717C23.899 17.2858 25.1848 16 26.7708 16Z"
            fill="#40C4FF"
          />
          <path
            d="M23.1784 41.1311C23.1784 42.7149 24.4642 43.9985 26.0501 43.9985C27.636 43.9985 28.9218 42.7149 28.9218 41.1311C28.9218 40.2323 28.9218 34.1478 28.9218 33.2454C28.9218 31.6616 27.636 30.3779 26.0501 30.3779C24.4642 30.3779 23.1784 31.6616 23.1784 33.2454C23.1784 34.1478 23.1784 40.2323 23.1784 41.1311Z"
            fill="#E91E63"
          />
          <path
            d="M16 33.2454C16 31.6616 17.2858 30.3779 18.8717 30.3779C19.7296 30.3779 21.7434 30.3779 21.7434 30.3779C21.7434 30.3779 21.7434 32.5181 21.7434 33.2454C21.7434 34.8291 20.4576 36.1128 18.8717 36.1128C17.2858 36.1128 16 34.8291 16 33.2454Z"
            fill="#E91E63"
          />
          <path
            d="M41.1279 36.8216C42.7138 36.8216 43.9996 35.5358 43.9996 33.9499C43.9996 32.3639 42.7138 31.0781 41.1279 31.0781C40.2276 31.0781 34.1345 31.0781 33.2306 31.0781C31.6447 31.0781 30.3589 32.3639 30.3589 33.9499C30.3589 35.5358 31.6447 36.8216 33.2306 36.8216C34.1338 36.8216 40.2276 36.8216 41.1279 36.8216Z"
            fill="#FFC107"
          />
          <path
            d="M33.2306 43.9993C31.6447 43.9993 30.3589 42.7135 30.3589 41.1276C30.3589 40.2697 30.3589 38.2559 30.3589 38.2559C30.3589 38.2559 32.5026 38.2559 33.2306 38.2559C34.8165 38.2559 36.1023 39.5417 36.1023 41.1276C36.1023 42.7135 34.8165 43.9993 33.2306 43.9993Z"
            fill="#FFC107"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="w-[50px] h-[50px] overflow-hidden rounded-[8.05px] bg-gray-50 border-[0.57px] border-[#f0efff] flex justify-center items-center">
      <div className="flex items-center justify-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36.8202 18.8717C36.8202 17.2858 35.5344 16 33.9485 16C32.3626 16 31.0768 17.2858 31.0768 18.8717C31.0768 19.772 31.0768 25.8651 31.0768 26.769C31.0768 28.3549 32.3626 29.6407 33.9485 29.6407C35.5344 29.6407 36.8202 28.3549 36.8202 26.769C36.8202 25.8651 36.8202 19.772 36.8202 18.8717Z"
            fill="#33D375"
          />
          <path
            d="M44 26.7682C44 28.3541 42.7142 29.6399 41.1283 29.6399C40.2704 29.6399 38.2566 29.6399 38.2566 29.6399C38.2566 29.6399 38.2566 27.4962 38.2566 26.7682C38.2566 25.1823 39.5424 23.8965 41.1283 23.8965C42.7142 23.8965 44 25.1823 44 26.7682Z"
            fill="#33D375"
          />
          <path
            d="M18.8717 23.1797C17.2858 23.1797 16 24.4655 16 26.0514C16 27.6373 17.2858 28.9231 18.8717 28.9231C19.772 28.9231 25.8651 28.9231 26.769 28.9231C28.3549 28.9231 29.6407 27.6373 29.6407 26.0514C29.6407 24.4655 28.3549 23.1797 26.769 23.1797C25.8651 23.1797 19.772 23.1797 18.8717 23.1797Z"
            fill="#40C4FF"
          />
          <path
            d="M26.7708 16C28.3567 16 29.6425 17.2858 29.6425 18.8717C29.6425 19.7297 29.6425 21.7435 29.6425 21.7435C29.6425 21.7435 27.4987 21.7435 26.7708 21.7435C25.1848 21.7435 23.899 20.4576 23.899 18.8717C23.899 17.2858 25.1848 16 26.7708 16Z"
            fill="#40C4FF"
          />
          <path
            d="M23.1784 41.1311C23.1784 42.7149 24.4642 43.9985 26.0501 43.9985C27.636 43.9985 28.9218 42.7149 28.9218 41.1311C28.9218 40.2323 28.9218 34.1478 28.9218 33.2454C28.9218 31.6616 27.636 30.3779 26.0501 30.3779C24.4642 30.3779 23.1784 31.6616 23.1784 33.2454C23.1784 34.1478 23.1784 40.2323 23.1784 41.1311Z"
            fill="#E91E63"
          />
          <path
            d="M16 33.2454C16 31.6616 17.2858 30.3779 18.8717 30.3779C19.7296 30.3779 21.7434 30.3779 21.7434 30.3779C21.7434 30.3779 21.7434 32.5181 21.7434 33.2454C21.7434 34.8291 20.4576 36.1128 18.8717 36.1128C17.2858 36.1128 16 34.8291 16 33.2454Z"
            fill="#E91E63"
          />
          <path
            d="M41.1279 36.8216C42.7138 36.8216 43.9996 35.5358 43.9996 33.9499C43.9996 32.3639 42.7138 31.0781 41.1279 31.0781C40.2276 31.0781 34.1345 31.0781 33.2306 31.0781C31.6447 31.0781 30.3589 32.3639 30.3589 33.9499C30.3589 35.5358 31.6447 36.8216 33.2306 36.8216C34.1338 36.8216 40.2276 36.8216 41.1279 36.8216Z"
            fill="#FFC107"
          />
          <path
            d="M33.2306 43.9993C31.6447 43.9993 30.3589 42.7135 30.3589 41.1276C30.3589 40.2697 30.3589 38.2559 30.3589 38.2559C30.3589 38.2559 32.5026 38.2559 33.2306 38.2559C34.8165 38.2559 36.1023 39.5417 36.1023 41.1276C36.1023 42.7135 34.8165 43.9993 33.2306 43.9993Z"
            fill="#FFC107"
          />
        </svg>
      </div>
    </div>
  );
};
