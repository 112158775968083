import { FC, HTMLAttributes, useEffect } from 'react'
import { NextSeo } from 'next-seo'
import { SITE_DATA } from '@constants/site-data'

const Layout: FC<any> = ({ children, title }) => {
  const pageTitle = title ? `${title} | ${SITE_DATA.title}` : SITE_DATA.title

  return (
    <div className="h-screen">
      <NextSeo
        title={pageTitle}
        canonical={SITE_DATA.canonical_url}
        description={SITE_DATA.description}
        twitter={{
          handle: SITE_DATA.twitter_user,
        }}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: SITE_DATA.canonical_url,
          site_name: SITE_DATA.title,
        }}
      />
      <main>{children}</main>
    </div>
  )
}

export default Layout
