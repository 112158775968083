import { useRouter } from "next/router";

const SocialCard = ({ text, author, title, icon, link }: any) => {
  const router = useRouter();

  return (
    <li
      onClick={() => {
        window.open(link, "_ blank");
      }}
      className="p-2 border bg-gray-50 rounded-3xl cursor-pointer group"
    >
      <figure className="relative flex flex-col justify-between h-full p-6 bg-white border shadow-lg rounded-2xl">
        <blockquote className="relative">
          <p className="text-base text-gray-500">{text}</p>
        </blockquote>
        <figcaption className="relative flex items-center justify-between pt-6 mt-6">
          <div>
            <div className="font-medium text-gray-900">{author}</div>
            <div className="mt-1 text-sm text-gray-500">{title}</div>
          </div>
          <div className="overflow-hidden rounded-full bg-gray-50 transition-all">
            {icon}
          </div>
        </figcaption>
      </figure>
    </li>
  );
};

export default SocialCard;
