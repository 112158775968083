import Image from 'next/image'
import { LandingPage } from '@components/landing/LandingPage'
import Layout from '@components/landing/Layout'

export default function Home() {
  return (
    <Layout>
      <main className={`bg-gray-100`}>
        <LandingPage />
      </main>
    </Layout>
  )
}
